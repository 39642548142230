import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    result: number
};

type Params = { homeownerId: number; claimId: number; slotIds: string; claimNumber: string; };

export const useDeclineSlots = createMutation<Response, Params, AxiosError>({
    mutationFn: async params =>
        axios({
            url: 'SchedulerAssignments/HomeOwnerLandingSlotsDecline',
            method: 'POST',
            params

        }).then(response => response.data),
});

import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';

type Params = {
    key: string,
}
export const useSLAParametersTime = createQuery<ISLACondition[], Params, AxiosError>({
    queryKey: ['slaTimeParameters'],
    fetcher: async (params) =>
        await axios.get('SchedulerAssignments/GetSLA?key=TimeSlotRange').then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});

export const useSLAParametersDate = createQuery<ISLACondition[], Params, AxiosError>({
    queryKey: ['slaDateParameters'],
    fetcher: async (params) =>
        await axios.get(`SchedulerAssignments/GetSLA?key=${params.key}`).then(response => response.data?.result || []),
    refetchOnWindowFocus: false
});

import React, { useEffect } from "react";
import { genNumericArray } from "../../../utils/uiutils";
import TimeDropdown from "./time-slot-single-dropdown";

interface ISelectTimeProps {
    onChangeTime: (time: string) => void
    hh: string;
    mm: string;
    a: string;
    ampmList?: any[];
}
const SelectTime: React.FunctionComponent<ISelectTimeProps> = (props) => {
    const { onChangeTime, hh, mm, a, ampmList } = props;
    let hours = genNumericArray(1, 12);
    const minutes = genNumericArray(0, 59);
    // const amPm = ["AM", "PM"];
    const [hour, setHours] = React.useState<string>(hh);
    const [min, setMin] = React.useState<string>(mm);
    const [ampm, setAmpm] = React.useState<string>(a);

    return (<>
        <div className='row '>
            <div className="col-4">
                <TimeDropdown isDisabled={false} isDef={{ text: props.hh, value: props.hh }} placeholder={hh} dataitems={hours.map(e => { return ({ text: e, value: e }) })} onchange={(value: string) => {
                    setHours(value);
                    onChangeTime(`${value}:${min} ${ampm}`)
                }} id='hour' />
            </div>
            <div className="col-4">
                <TimeDropdown isDisabled={true} isDef={{ text: mm, value: mm }} placeholder={mm} dataitems={minutes.map(e => { return { text: e, value: e } })} onchange={(value: string) => {
                    setMin(value)
                    onChangeTime(`${hour}:${value} ${ampm}`)
                }} id='minutes' />
            </div>
            <div className="col-4">
                <TimeDropdown isDisabled={false} isDef={{ text: a, value: a }} dataitems={ampmList.map(e => { return { text: e, value: e } })} onchange={(value: string) => {
                    setAmpm(value);
                    onChangeTime(`${hour}:${min} ${value}`)
                }} id='ampm' />
            </div>

        </div>
    </>);
};

export default SelectTime;
import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import {
    IBasicDetails,
    InspectorLandingInterface,
    InspectorSlot,
} from '../../models/scheduler/inspector-landing';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { parseDate, formatDate } from '@progress/kendo-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { forEach, isEmpty } from 'lodash';
import { stat } from 'fs';
import { SLOT_TYPES, WINDOWS_DOORS_API_URL } from '../../constants';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import axios from 'axios';
import CheckboxGroup, { ICheckboxItemProps } from '../../components/checkbox-group-field';
import { convertToTimezoneWithDateString } from '../../utils';
import CalenderIcon from '../../images/calender-icon';
import { check } from 'prettier';

interface IInspectorLandingProps { }

const InspectorLanding: React.FunctionComponent<IInspectorLandingProps> = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { state } = loc;
    const [isValid, setValid] = useState<boolean>(false);
    const URL_INSPECTOR_AVAILABILITY = "/inpsector/inspector-availability";

    const { isError, isLoading, reload, result } = useLoadData<InspectorLandingInterface[]>(
        'SchedulerAssignments/GetInspectorLandingDetails?InspectorID=' + state?.schUsers?.userId
    );
    const [data, setData] = React.useState<{ b: IBasicDetails[]; slot: InspectorSlot[] }>({
        b: undefined,
        slot: undefined,
    });
    const [accept, setAccept] = useState<{
        isAccept: boolean;
        data: { b?: IBasicDetails[]; inspSlots?: string; selctedTimeSlot?: ICheckboxItemProps[] };
    }>({ data: undefined, isAccept: false });
    const [dateValidation, setDateValidation] = useState<boolean>(false);
    let basicDetails: IBasicDetails[] = [];
    let inspectorSlots: InspectorSlot[][] = [];
    let slots: InspectorSlot[] = [];
    const [isLoadData, setLoadData] = useState<boolean>(false);

    const toggleDialogValidation = () => {
        setDateValidation(false);
    };
    React.useEffect(() => {

        if (result) {
            result.forEach((e) => {

                if (e.basicDetails) {
                    basicDetails.push(e.basicDetails);
                } else {
                    inspectorSlots.push(e.inspectorSlots);
                }
            });
            slots = inspectorSlots.flat();
            if (state) {
                state.norecord = 0;
            }
            setData({ b: basicDetails, slot: slots });

        }

    }, [result]);

    const handleAccept = async () => {

        const CLAIM_ID = accept.data?.b[0].claimID;
        const CLAIM_NO = accept.data?.b[0].claimNo;
        const INSPECTOR_ID = state?.schUsers?.userId;
        const slotsvalueList = accept.data?.inspSlots;

        if (CLAIM_ID > 0 && INSPECTOR_ID > 0 && slotsvalueList?.length > 0) {
            setLoadData(true);
            const response = await axios.post(`SchedulerAssignments/InspectorLandingSlotAccept?schedulerId=0&claimId=${CLAIM_ID}&claimNumber=${CLAIM_NO}&inspectorId=${INSPECTOR_ID}&slotIds=${encodeURIComponent(slotsvalueList)}`)
            if (response.data.result === -1) {
                setAccept({ data: undefined, isAccept: false })
            }
            reload();
            setLoadData(false);
        } else {
            console.log("Parameter Missing..")
            setLoadData(false);
        }
    };


    return (
        <>
            {isLoading && <LoadingIndicator isLoading />}
            {isError && (
                <>
                    <div>Check NetWork conenction</div>
                </>
            )}
            {dateValidation && (
                <Dialog
                    title={'Validation'}
                    onClose={toggleDialogValidation}
                    style={{ borderRadius: '10px' }}
                    className="delete-dialog"
                >
                    <div>
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                <p style={{ fontSize: '17px' }}>
                                    Select time slot(s) to accept.
                                </p>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {!isLoading && result && (
                <div className="m-2 p-2">
                    <div className="d-flex justify-content-between align-items-center mb-3 ">
                        <div className='font-bold' >{'job offers'.toUpperCase()}</div>

                        <button
                            className="btn btn-light d-flex align-items-center sw-shadow"
                            onClick={() => {
                                nav('/inspector/inspector-availability', { state: state });
                            }}
                        >
                            <CalenderIcon />
                            My Availability
                        </button>
                    </div>

                    <div className='rescol-2'>
                        {data &&
                            data?.b?.map((e) => (

                                <>

                                    <div className="gredientBg card p-4 border-0 sw-shadow radius-15 border-0 pb-40 ">
                                        <LoadingIndicator isLoading={isLoadData} ></LoadingIndicator>
                                        <div className="res-grd">
                                            <div>
                                                <p className="mb-1 text-secondary">
                                                    Scope of Work
                                                </p>
                                                <h5>{e?.scopeOfWork}</h5>
                                            </div>
                                            <div>
                                                <p className="mb-1 text-secondary">Due</p>
                                                <h5 className="text-danger">
                                                    {e?.due ? Number(e.due) < 0 ? "Overdue" : e.due + " hours" : ""}
                                                </h5>
                                            </div>
                                            <div>
                                                <p className="mb-1 text-secondary">Location</p>
                                                <h5>{e?.location ?? '-'}</h5>
                                            </div>
                                        </div>
                                        {data.slot.filter((s) => s.claimID === e.claimID)
                                            .length > 0 ? (

                                            <>
                                                <div>
                                                    <label className="mb-2 mt-2 text-secondary">
                                                        Date Time
                                                    </label>
                                                    {data.slot.filter(s => s.claimID === e.claimID).length > 1 && (

                                                        <CheckboxGroup
                                                            items={data.slot.filter(s => s.claimID === e.claimID).map((f, i) => ({
                                                                label: `${convertToTimezoneWithDateString(f?.dateFrom).format('MM/DD/YYYY hh:mm A')} - ${convertToTimezoneWithDateString(f?.dateTo).format('hh:mm A')}`,
                                                                value: f.slotID,
                                                                isUnAvailable: f.isUnavailable,
                                                                isAccept: f.isAccepted,

                                                                b_Status: f.b_Status,
                                                                b_StatusCode: f.b_StatusCode
                                                            }))} onchange={(selected: ICheckboxItemProps[]) => {
                                                                setAccept({ data: { b: [e], inspSlots: selected?.map((item) => parseInt(item.value))?.join(","), selctedTimeSlot: selected }, isAccept: false })
                                                            }}></CheckboxGroup>)}

                                                    {data.slot.filter(s => s.claimID === e.claimID).length === 1 && (data.slot.filter(s => s.claimID === e.claimID).map((f, i) => {
                                                        return <>
                                                            <br></br>
                                                            <div style={{ fontWeight: "bold" }}>
                                                                {`${convertToTimezoneWithDateString(f?.dateFrom).format('MM/DD/YYYY hh:mm A')} - ${convertToTimezoneWithDateString(f?.dateTo).format('hh:mm A')}`}
                                                            </div>
                                                        </>
                                                    }))}
                                                </div>
                                                <div className='gredientcardFooter'>
                                                    {parseInt(e?.due) > 0 ?
                                                        (<>
                                                            <a
                                                                className="text-decoration-underline d-block "
                                                                style={{ color: 'orange', cursor: "pointer", marginRight: "50px" }}
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    nav('/inspector/inspector-availability-by-claims', { state: { claimId: e.claimID, inspectorId: state?.schUsers?.userId, state, location: e.location, scopeOfWork: e.scopeOfWork } });
                                                                }}
                                                            >
                                                                Provide New
                                                            </a>
                                                            <button
                                                                onClick={() => {
                                                                    if (accept?.data?.inspSlots.length > 0) {
                                                                        setAccept({ isAccept: true, data: accept.data });
                                                                        //handleAccept;
                                                                    } else {
                                                                        setDateValidation(true);
                                                                    }

                                                                }}
                                                                className="btn btn-primary btn-md text-capitalize"
                                                            >
                                                                Accept
                                                            </button></>) : (<div className='mt-2'></div>)}
                                                </div>
                                            </>
                                        ) : (
                                            <div className='gredientcardFooter'>
                                                {parseInt(e?.due) > 0 ?
                                                    (<div className="scheduler-arrow-right" >
                                                        <a
                                                            style={{ color: 'orange', marginRight: "50px" }}
                                                            className="text-decoration-underline"
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                nav('/inspector/inspector-availability-by-claims', { state: { claimId: e.claimID, inspectorId: state?.schUsers?.userId, state, location: e.location, scopeOfWork: e.scopeOfWork } });
                                                            }}
                                                        >
                                                            Provide Availability
                                                        </a>
                                                    </div>) : (null)}
                                            </div>
                                        )}
                                    </div >

                                </>
                            ))}
                    </div>

                    {isEmpty(result) && (
                        <div>
                            <h4>No Record Found..</h4>
                        </div>
                    )}
                </div >
            )}
            {
                accept.isAccept && (<Dialog className='acceptpopup' onClose={() => { setAccept({ data: accept.data, isAccept: false }); }}>
                    <center style={{ marginLeft: '-15px', marginRight: '-15px', marginBottom: '-16px' }} className='gredientBg90 content'>

                        <div className="gridHeaderTs">
                            <div >
                                <small>Location </small>
                                <div className='mt-2' style={{ fontWeight: 'bold' }}>{result[0].basicDetails?.location}</div>
                            </div>

                            <div >
                                <small>Scope Of Work</small>
                                {<div className='mt-2' style={{ fontWeight: 'bold' }}> {result[0].basicDetails?.scopeOfWork}</div>}

                            </div>
                        </div>


                        <div>


                            <p>Please confirm that you wish to select the below time slot(s) for the home inspection. Once approved, the insured will be notified, and you will receive a confirmation email.</p>

                            <div className='mt-4'>
                                Selected time slot(s)
                                <div style={{ fontWeight: 'bold' }} className='mt-4'>{accept?.data?.selctedTimeSlot?.map((item) => item.label).join(",  ")}</div>
                            </div>
                        </div>
                    </center>

                    <DialogActionsBar>
                        <div className='row'>
                            <div className='d-flex justify-content-between' >
                                <button className='btn btn-sm btn-secondary' onClick={() => {
                                    setAccept({ data: accept.data, isAccept: false });
                                }}>Cancel</button>
                                <button className='btn btn-primary btn-sm' onClick={async () => {
                                    if (data.slot.filter(e => data.b[0]?.claimID === e?.claimID).length == 1) {
                                        accept.data.inspSlots = data.slot[0]?.slotID
                                    }
                                    await handleAccept();
                                }}>Accept</button>

                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog >)
            }
        </>
    );
};

export default InspectorLanding;

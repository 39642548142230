
export const formatPhoneNumber = (phoneNumber: string) => {
    // Remove any non-numeric characters
    const numericPhoneNumber = phoneNumber?.replace(/\D/g, "");

    // Apply formatting based on the length of the numeric string

    if (numericPhoneNumber?.length === 10) {
        return `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(
            3,
            6,
        )}-${numericPhoneNumber?.slice(6)}`;
    }

    return numericPhoneNumber;
};

export const enum CLAIM_STATUS_CODE {
    AVAILABLE = "AVALBL",
    AWATINGAVAILABILITY = "AWTAVL",
    CANCELED = "CANCEL",
    DECLINED = "DCLIND",
    READY_TO_SCHEDULE = "RDYSCH",
    RECEIVED = "RECVED",
    RECEIVED_RESCHEDULE = "RESCHD",
    SCHEDULED = "SCHEDL",
    UNAVAILABLE = "UNAVAIL"
}

export const validateEmailByString = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
};
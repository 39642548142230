import * as React from 'react';





import '@progress/kendo-date-math/tz/MST7MDT'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AssignedUser, ClaimSlotsResponse, useCheckAutoSuggestedSlot, useCheckScheduleStatus, useScheduledSlots } from '../../../api/claim';
import { Calendar } from './calendar';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { Slot } from '../../../types/Slot';
import { useCurrentUser } from '../../../hooks/user-current-user.hook';
import { AddAvailabilityPayload, useAddAvailabilitySlots, useScheduleDeleteHomeownerSlot, } from '../../../api/homeowner';
import { useAssignInspectorQB, useInspectors, useScheduleDeleteInspectorSlot, } from '../../../api/inspector';
import { Alert } from './delete-dialog';

import { toast } from 'react-toastify'
import { IInspector } from '../../../models/scheduler/inspector';
import { SLOT_TYPES, TIME_ZONE } from '../../../constants';
import { convertToTimezone, convertToTimezoneWithDateString } from '../../../utils';
import SingleDropdown from '../../../components/single-select-dropdown';

dayjs.extend(utc);
dayjs.extend(timezone);

interface UserItemProps {
    title: string;
    data: string
}

export function UserItem({ title, data }: UserItemProps) {
    return (
        <div className='d-flex flex-column'>
            <label>
                {title?.toUpperCase()}
            </label>
            <p style={{ fontWeight: 'bold' }}>
                {data}
            </p>
        </div>
    );
}




function SelectedSlotToSchedule({
    selectedSlot,
    onSelectInspector,
    inspectorIdError,
    selectedInspector,
    inspectors }: {
        selectedSlot: Slot;
        onSelectInspector: (inspectorId: string, inspector: any) => void;
        inspectorIdError: string;
        inspectors: IInspector[];
        selectedInspector: any;
    }) {


    const formattedInspectorsForDropdown = React.useMemo(() => {
        return inspectors.map((inspector) => ({ text: inspector.inspector_Name, value: inspector.userID?.toString() }))
    }, [inspectors])

    const [selectedInspectorId, setSelectedInspectorId] = React.useState('')



    return <div className='selectedSlots' >

        <SingleDropdown
            id="inspector"
            onchange={(inspectorId: string, inspector: any) => {
                onSelectInspector(inspectorId, inspector);
                setSelectedInspectorId(inspectorId)
            }}
            dataitems={formattedInspectorsForDropdown}
            isDef={selectedInspector}
            value={selectedInspectorId || selectedInspector?.value || "-1"}



        />

        <div>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('dddd, MMMM DD, YYYY')}</div>
        <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('hh:ss A')}</div>
        <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.end).tz(TIME_ZONE).format('hh:ss A')}</div>
    </div>
}



// const addInspectors = (inspectors: IInspector[], assignedUsers: { [key: string]: AssignedUser }): { [key: string]: AssignedUser } => {
//     return inspectors.reduce((updatedAssignedUsers: { [key: string]: AssignedUser }, inspector) => {
//         updatedAssignedUsers[inspector.userID + "Inspector"] = {
//             scheduledUserId: inspector.userID,
//             scheduledUserName: inspector.inspector_Name,
//             schedulerGroupName: <div className='d-flex flex-column align-items-center'>
//                 <span className='fw-bold'>{inspector.inspector_Name}</span>
//                 <div><small className="text-secondary">Assigned: 0</small></div>
//             </div>,
//             userType: "Inspector",
//             scheduledUserIdForCalendar: inspector.userID + "Inspector"
//         }
//         return updatedAssignedUsers
//     }, { ...assignedUsers })

// }


export type ScheduleClaimHandler = {
    reloadCalendar: () => void
}


export const ScheduleClaimProfile = React.forwardRef<ScheduleClaimHandler, { claim: Scheduler; claimStatus: string; onAddOrDeleteSchedule: () => void }>(({ claim, onAddOrDeleteSchedule, claimStatus }, ref) => {

    const [selectedSlot, setSelectedSlot] = React.useState<Slot>()
    const { userID, userName } = useCurrentUser()
    const [slots, setSlots] = React.useState<{ [key: string]: Slot }>({})
    const [scheduledUsers, setScheduledUsers] = React.useState<{ [key: string]: AssignedUser }>({})
    const homeownerSlots = React.useRef<{ [key: string]: Slot }>()
    const { data: inspectors, isLoading: isLoadingInspectors } = useInspectors({
        variables: { claimID: claim?.claimID }
    })

    const { mutate: checkAutoSuggestedSlot, isPending: isPendingCheckAutoSuggestedSlot } = useCheckAutoSuggestedSlot()

    const autoSuggestLoaded = React.useRef(false)

    const { mutate: fetchScheduledSlots, isPending: isLoadingScheduledSlots } = useScheduledSlots({
        onSuccess: (response: ClaimSlotsResponse) => {
            setSlots(response.slots);
            setScheduledUsers(response.assignedUsers)
            homeownerSlots.current = response.homeownerSlots

            handleAutoSuggestedSlot(response.slots)
        }
    })

    const selectedStartAndEndDatesRef = React.useRef<{ start: string, end: string }>({ start: null, end: null })

    const getSlots = (claimId: number) => {

        if (selectedStartAndEndDatesRef.current.start && selectedStartAndEndDatesRef.current.end && !isLoadingInspectors) {
            fetchScheduledSlots({ claimId, startDate: selectedStartAndEndDatesRef.current.start, endDate: selectedStartAndEndDatesRef.current.end })
        }
    }



    React.useEffect(() => {
        getSlots(claim.claimID)
    }, [inspectors])




    const backupSlot = React.useRef<Slot>()





    const handleSelectSlot = ({ start, end, resource, lastSlots, title }: { title?: string; start: Date; end: Date; resource?: AssignedUser, lastSlots?: { [key: string]: Slot } }) => {

        const isPrevDay = convertToTimezone(start).isBefore(convertToTimezone(new Date()), 'day')
        if (isPrevDay) return

        const startDateISOString = dayjs(start).toISOString()


        const slot: Slot = {
            id: startDateISOString + resource.scheduledUserId + resource.userType,
            title: title || '',
            start,
            end,
            statusCode: SLOT_TYPES.temp,
            ...resource
        }




        if (selectedSlot?.id === slot.id && selectedSlot.statusCode === SLOT_TYPES.temp) {
            return
        }


        const slotsToUpdate = (lastSlots || slots)
        const prevSlots = { ...slotsToUpdate }
        if (selectedSlot) {
            delete prevSlots[selectedSlot.id]
        }

        setSelectedSlot(slot)
        if (resource.userType === "Inspector") {
            setSelectedInspector({ text: slot.scheduledUserName, value: slot.scheduledUserId?.toString() })
            setInspectorIdError("")
        }
        else {
            setSelectedInspector(null)
        }

        const lastBackupSlot = backupSlot.current



        if (!!slotsToUpdate[slot.id]) {
            backupSlot.current = slotsToUpdate[slot.id]
        }
        else {
            backupSlot.current = null
        }

        if (lastBackupSlot) {
            prevSlots[lastBackupSlot.id] = lastBackupSlot
        }

        prevSlots[slot.id] = slot
        setSlots(prevSlots)
    }




    const handleAutoSuggestedSlot = (lastSlots: { [key: string]: Slot; }) => {

        checkAutoSuggestedSlot({ claimId: claim?.claimID },
            {
                onSuccess: (response) => {
                    const resource: AssignedUser = {
                        scheduledUserId: response.inspectorID,
                        scheduledUserName: response.inspector,
                        userType: 'Inspector',
                        scheduledUserIdForCalendar: response.inspectorID + 'Inspector',
                    }
                    const start = convertToTimezoneWithDateString(response.slotFrom).toDate();
                    const end = convertToTimezoneWithDateString(response.slotTo).toDate();
                    handleSelectSlot({ start, end, resource, lastSlots, title: "Recommended" })
                    autoSuggestLoaded.current = true

                }
            }
        );
    }


    const { mutate: deleteScheduledHomeownerSlot, isPending: isPendingDeleteScheduledHomeownerSlot } = useScheduleDeleteHomeownerSlot()
    const { mutate: deleteScheduledInspectorSlot, isPending: isPendingDeleteScheduledInspectorSlot } = useScheduleDeleteInspectorSlot()




    const slotToDelete = React.useRef<Slot>()
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

    const handleDeleteSlot = (slot: Slot) => {




        if (slot.statusCode === SLOT_TYPES.temp) {
            const slotsToDelete = { ...slots }
            delete slotsToDelete[slot.id]
            if (backupSlot.current) {
                slotsToDelete[backupSlot.current.id] = backupSlot.current
                backupSlot.current = null
            }
            setSelectedSlot(undefined)
            setSlots(slotsToDelete)
            return
        }

        slotToDelete.current = slot
        setShowDeleteDialog(true)
    }



    const deleteSavedSlot = () => {
        setShowDeleteDialog(false);
        const slot = slotToDelete.current
        const slotsToDelete = { ...slots }

        const handleDeleteSuccess = () => {
            delete slotsToDelete[slot.id]
            setSlots(slotsToDelete)
            slotToDelete.current = null
            getSlots(claim.claimID);
            onAddOrDeleteSchedule?.()
        }

        const apiActionToDeleteSlot = slot.userType?.toLowerCase() === "homeowner" ? deleteScheduledHomeownerSlot : deleteScheduledInspectorSlot

        apiActionToDeleteSlot({ claimNumber: claim.claimNo, slotId: slot.slotId, inspectorId: slot.scheduledUserId, userId: userID, userName, homeownerId: slot.scheduledUserId, }, {
            onSuccess: () => handleDeleteSuccess()
        })
    }

    const { mutate: checkSlotStatus, isPending: isLoadingCheckSlotStatus } = useCheckScheduleStatus()

    const { mutate: addAvailability, isPending: isPendingAddAvailability } = useAddAvailabilitySlots({
        onSuccess: () => {
            getSlots(claim.claimID);
            setSelectedSlot(null)
            onAddOrDeleteSchedule?.()
        }
    })

    const [statusAlertOptions, setStatusAlertOptions] = React.useState<{ show: boolean; content: string }>({ show: false, content: '' })

    const handleSchedule = () => {

        checkSlotStatus({
            claimId: claim.claimID, homeownerId: claim.homeowner_ID, fromDate: selectedSlot.start.toISOString(), toDate: selectedSlot.end.toISOString(), inspectorId: Number(selectedInspector.value)
        }, {
            onSuccess: (response) => {
                if (response.homeownerStatusCode === SLOT_TYPES.scheduled && response.inspectorStatusCode === SLOT_TYPES.scheduled) {
                    const content = "There are already scheduled slots for both homeowner and inspector. Do you want to continue?"
                    setStatusAlertOptions({ show: true, content })
                    return
                }

                if (response.homeownerStatusCode === SLOT_TYPES.scheduled) {
                    const content = "There is already scheduled slot for homeowner. Do you want to continue?"
                    setStatusAlertOptions({ show: true, content })
                    return
                }

                if (response.inspectorStatusCode === SLOT_TYPES.scheduled) {
                    const content = "There is already scheduled slot for inspector. Do you want to continue?"
                    setStatusAlertOptions({ show: true, content })
                    return
                }

                handleAddAvailability()
            }
        })

    }

    const { mutate: assignInspectorToQB } = useAssignInspectorQB()


    const handleAddAvailability = () => {

        const selectedInspectorObj = inspectors.find(inspector => inspector.userID === Number(selectedInspector.value))

        if (!!selectedInspectorObj.inspectorQuickBaseId && !!claim.keyStoneNo) {
            const params = {
                taskID: claim.taskID ? Number(claim.taskID) : 0,
                assignmentNumber: claim.keyStoneNo,
                inspectorQbaseId: selectedInspectorObj.inspectorQuickBaseId,
                inspectorName: selectedInspectorObj.inspector_Name,
                scheduledStartDateTime: convertToTimezone(selectedSlot.start).format(),
                scheduledEndDateTime: convertToTimezone(selectedSlot.end).format(),
                claimId: claim.claimID
            }
            assignInspectorToQB(params)
        }



        const payload: AddAvailabilityPayload[] =
            [{ fromDateTime: selectedSlot.start.toISOString(), toDateTime: selectedSlot.end.toISOString(), claimID: claim.claimID, homeOwnerID: claim.homeowner_ID, slotStatusCode: SLOT_TYPES.scheduled, schedulerID: userID, inspectorID: Number(selectedInspector.value) }]
        addAvailability({ payload, params: { claimNumber: claim.claimNo } })
    }



    React.useImperativeHandle(ref, () => {
        return {
            reloadCalendar: () => {
                getSlots(claim.claimID)
            }
        };
    }, [isLoadingInspectors]);


    const [selectedInspector, setSelectedInspector] = React.useState<any>()

    const [inspectorIdError, setInspectorIdError] = React.useState<string>("")

    const handleSelectInspector = (inspectorId: string, inspector: any) => {

        handleSelectSlot({ start: selectedSlot.start, end: selectedSlot.end, resource: { userType: "Inspector", scheduledUserId: Number(inspectorId), scheduledUserIdForCalendar: inspectorId + "Inspector", scheduledUserName: inspector.text } })

        setSelectedInspector(inspector)
        setInspectorIdError("")
    }


    return (
        <div>
            <div className='claim-profile-calendar'>
                <LoadingIndicator isLoading={isPendingDeleteScheduledHomeownerSlot || isPendingDeleteScheduledInspectorSlot || isLoadingScheduledSlots || isLoadingInspectors || isLoadingCheckSlotStatus} />
                {!isLoadingInspectors && <Calendar claimNumber={claim.claimNo} claimStatus={claimStatus} selectedSlot={selectedSlot} source='claim' onSelectSlot={handleSelectSlot}
                    onChangeDate={(start, end) => {
                        selectedStartAndEndDatesRef.current = { start, end }
                        getSlots(claim.claimID)
                    }}
                    onDelete={handleDeleteSlot}
                    slots={slots}
                    onlyShowDay
                    group={{
                        resources: ["Persons"],
                        orientation: 'horizontal',

                    }}

                    resources={[
                        {
                            name: "Persons",
                            data: Object.values(scheduledUsers),
                            field: "scheduledUserIdForCalendar",
                            valueField: "scheduledUserIdForCalendar",
                            textField: "schedulerGroupName",
                        },
                    ]}

                />}
            </div>

            <div className='d-flex justify-content-between'>


                {!!selectedSlot && claim?.status_ID !== 7 ? (<>

                    <SelectedSlotToSchedule selectedInspector={selectedInspector} selectedSlot={selectedSlot} onSelectInspector={handleSelectInspector} inspectorIdError={inspectorIdError} inspectors={inspectors} />


                    <button style={claim?.status_ID !== 7 ? { display: "visible" } : { display: "none" }} className="btn-primary btn-sm me-1 btn mt-3" onClick={() => {
                        handleSchedule();
                    }} disabled={isPendingAddAvailability || !selectedSlot || isLoadingCheckSlotStatus}>
                        {(!isPendingAddAvailability) && "Schedule"}
                        {(isPendingAddAvailability) && <LoadingIndicator isLoading={isPendingAddAvailability || isLoadingCheckSlotStatus} showLabel={false} size='sm' />}
                    </button>

                </>) : <div></div>}
            </div>
            {showDeleteDialog && <Alert onClose={() => setShowDeleteDialog(false)} onSubmit={deleteSavedSlot} />}
            {statusAlertOptions.show && <Alert onClose={() => setStatusAlertOptions({ show: false, content: "" })} title='Schedule' subTitle={statusAlertOptions.content} onSubmit={() => {
                handleAddAvailability();
                setStatusAlertOptions({ show: false, content: "" })
            }} />}
        </div>
    );
})


import * as React from 'react';
import { useLoadData } from '../../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { Timeline, TimelineEventProps } from '@progress/kendo-react-layout';
import { IClaimHistoryLog } from '../../../models/scheduler/claim-history-log';
import { isEmpty } from 'lodash';
import { convertToTimezoneWithDateString } from '../../../utils';
import dayjs from 'dayjs';
import { parseDate } from '@progress/kendo-intl';
import Item from './claim-history-item';

interface IClaimHistoryProps {
    claimID: number;
    claimNumber?: string;
}

type GroupedClaimHistory = {
    activityDate: string;
    logs: IClaimHistoryLog[];
}

const ClaimHistory: React.FunctionComponent<IClaimHistoryProps> = (props) => {
    const { claimID, claimNumber } = props;
    const { isError, isLoading, reload, result } = useLoadData<IClaimHistoryLog[]>(`SchedulerAssignments/GetClaimHistoryForProfile?claimID=${claimID}&&claimNumber=${claimNumber}`)
    const [groupedClaimHistory, setGroupedClaimHistory] = React.useState<GroupedClaimHistory[]>()

    React.useEffect(() => {
        if (result) {


            const groupedHistory: GroupedClaimHistory[] = Object.values(result.reduce<Record<string, GroupedClaimHistory>>((acc, log) => {
                const date = convertToTimezoneWithDateString(log.logDate).format('DD/MM/YYYY'); // Format date as DD/MM/YYYY
                if (!acc[date]) {
                    acc[date] = { activityDate: date, logs: [] };
                }
                acc[date].logs.push(log);
                return acc;
            }, {}));


            setGroupedClaimHistory(groupedHistory)


        }

    }, [result])

    return (<>
        {/* loading */}
        {isLoading && (<LoadingIndicator isLoading />)}
        {/* error */}
        {isError && (<>
            <center>
                Error Loading ClaimHistory
            </center>
        </>)}
        {/* empty list */}
        {!result?.length && (<><center>
            Claim History is Empty
        </center></>)}
        {/* succesfulList */}
        {!!result?.length && (


            <div >

                {groupedClaimHistory?.map((history) => <Item activityDate={history.activityDate} logs={history.logs} />)}
                {/* <Timeline events={events} alterMode={false} dateFormat='MMM dd,yyyy hh:mm a'></Timeline> */}
            </div>


        )}



    </>);
};

export default ClaimHistory;
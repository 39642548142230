
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { Grid, GridColumn, GridColumnMenuFilter, GridColumnMenuProps, GridColumnMenuSort, GridDataStateChangeEvent, GridExpandChangeEvent, GridFilterCell, GridNoRecords, GridPageChangeEvent } from '@progress/kendo-react-grid';

import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { DataResult, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { Link, useNavigate } from 'react-router-dom';
import DashboardCount from './components/dashboard-count-component';
import mobileImage from '../../images/mobile-image.png';


import CheckAvailability from './check-availability';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { isEmpty } from 'lodash';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import dayjs from 'dayjs';
import {
    PagerTargetEvent,
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { ScheduleHomeowner } from './components/schedule-homeowner';
import { CLAIM_STATUS_CODE, formatPhoneNumber } from './utils';
import { convertToTimezone, convertToTimezoneWithDateString } from '../../utils';
import { STATUS_CODES } from 'http';
import { useSchedulerAssignments } from '../../api/dashboard';
import { string } from 'yup';


interface ISchedulerDashboardProps {
}


const DEFAULT_DATES = {
    start: new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")),
    end: new Date()
}

const SchedulerDashboard: React.FunctionComponent<ISchedulerDashboardProps> = () => {
    ///state variables
    const initialDataState: State = {
        take: 50,
        skip: 0,
        group: [],
    };

    const [isPagination, setIsPagination] = useState<boolean>(true);
    const [showHomeownerScheduler, setShowHomeownerScheduler] = useState(false)
    const [fromDate, setFromDate] = useState<Date>(new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")))
    const [toDate, setToDate] = useState<Date>(new Date())
    let maxDate = new Date();

    const [startAndEndDates, setStartAndEndDates] = useState<{ startDate: string; endDate: string }>({
        startDate: convertToTimezone(DEFAULT_DATES.start).startOf("day").toISOString(),
        endDate: convertToTimezone(DEFAULT_DATES.end).endOf("day").toISOString(),
    })


    const inputSearchRef = useRef(null);
    const chkRef = useRef<Scheduler>(null);
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });

    const [resultData, setResultData] = useState<DataResult>();

    const [dataState, setDataState] = useState<State>(initialDataState);

    const [availability, setChkAvailability] = useState<boolean>(false)
    ///final url

    const { data: schedulerAssignments, isLoading: isLoadingSchedulerAssignments, isError: isErrorSchedulerAssignments, refetch: reloadSchedulerAssignments } = useSchedulerAssignments({
        variables: {
            SDate: startAndEndDates.startDate,
            FDate: startAndEndDates.endDate,
        }
    })



    ///data fetch hook useloaddata
    const [isLoadingRequest, setloadingRequest] = useState(false);
    const [showRequestAvailabilityStatus, setShowRequestAvailabilityStatus] = useState(false);
    const nav = useNavigate();
    const [cancelFilter, setCancelFilter] = useState(false);
    const [lastFilterStatus, setLastFilterStatus] = useState(String);
    const [collapsedState, setCollapsedState] = React.useState([]);
    const filteredDataRef = useRef<Scheduler[]>([]);
    const refSearchText = useRef<string>("");
    const [isValid, setValid] = useState<boolean>(false);
    const childRef = useRef();
    const [isInactiveFilter, setInactiveFilter] = useState<string>("");


    const processWithGroups = (data: Scheduler[], dataState: State) => {
        const groups = dataState.group;
        dataState.group = groups;
        const newDataState = process(data, dataState);
        setGroupIds({ data: newDataState.data, group: dataState.group });
        return newDataState;
    };

    ///init method
    useEffect(() => {
        if (schedulerAssignments !== undefined) {
            // result.forEach(e => {
            //     e.createdOn = convertToTimezoneWithDateString(e.createdOn).format('MM/DD/YYYY');
            //     e.scheduleOn = convertToTimezoneWithDateString(e.scheduleOn).format('MM/DD/YYYY');
            //     e.dateofLoss = formatDate(parseDate(e.dateofLoss), 'MM/dd/yyyy')
            // })
            const DataRes = cancelFilter === true ? process(filteredDataRef.current, dataState) : process(schedulerAssignments, dataState);
            setGridData(DataRes);
            cancelFilter === true ? setResultData(processWithGroups(filteredDataRef.current, dataState)) : setResultData(processWithGroups(schedulerAssignments, dataState));
        }
    }, [schedulerAssignments, dataState]);

    useEffect(() => {
        if (cancelFilter === true) {
            setDataState(initialDataState)
        }
    }, [filteredDataRef.current])

    const expandChange = (event: GridExpandChangeEvent) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    let newData: any
    if (resultData) {
        newData = setExpandedState({
            data: resultData.data,
            collapsedIds: collapsedState,
        });
    }
    const onStatusFilterToggle = (status: string) => {
        setInactiveFilter("");
        if (!cancelFilter || lastFilterStatus !== status) {
            inputSearchRef.current.value = "";
            const filteredData = status?.toLowerCase() === "pending" ?
                schedulerAssignments?.filter((p) => p.statusCode?.toLowerCase() !== CLAIM_STATUS_CODE.SCHEDULED?.toLocaleLowerCase() &&
                    p.statusCode?.toLowerCase() !== CLAIM_STATUS_CODE.CANCELED?.toLocaleLowerCase())
                :
                schedulerAssignments?.filter((p) => p.statusCode?.toLowerCase() === status?.toLowerCase());
            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            setCancelFilter(true);
            filteredDataRef.current = filteredData;
        } else {
            setGridData(process(schedulerAssignments, dataState));
            setResultData(process(schedulerAssignments, dataState));
            filteredDataRef.current = schedulerAssignments;
            setCancelFilter(false)
        }
        setLastFilterStatus(status);
        setIsPagination(true)
    };


    const onDateFilterToggle = (status: string) => {
        setInactiveFilter("");
        const current = new Date();
        const tomorrow = current.setDate(current.getDate() + 1);
        if (!cancelFilter || lastFilterStatus !== status) {
            const filteredData = status?.toLowerCase() === "today" ?
                schedulerAssignments.filter((p) => formatDate(new Date(p?.scheduleOn), "MM/dd/yyyy") === formatDate(new Date(), "MM/dd/yyyy") && p?.status_ID === 5) :
                status?.toLowerCase() === "tomorrow" ? schedulerAssignments.filter((p) => (formatDate(new Date(p?.scheduleOn), "MM/dd/yyyy") === formatDate(new Date(tomorrow), "MM/dd/yyyy")) && p?.status_ID === 5) :
                    schedulerAssignments.filter((p) => dayjs(p?.scheduleOn) >= dayjs().startOf("week") && dayjs(p?.scheduleOn) < dayjs().endOf("week") && p?.status_ID === 5);

            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            setCancelFilter(true);
            filteredDataRef.current = filteredData;
        } else {
            setGridData(process(schedulerAssignments, dataState));
            setResultData(process(schedulerAssignments, dataState));
            setCancelFilter(false);
            filteredDataRef.current = [];

        }
        setIsPagination(true)
        setLastFilterStatus(status);
    };

    const onSearch = (e: any) => {
        setInactiveFilter("ALL");
        setCancelFilter(true)
        if (e.target.value.length > 0) {
            const searchKey: string = e.target.value;
            const filteredData = schedulerAssignments?.filter(
                (p) =>
                    p?.claimNo?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.policyNo?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.keyStoneNo?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.address?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.state?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.homeOwner?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.homeowneravailability?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.homeOwner_Phone?.toLowerCase().includes(searchKey.toLowerCase()) ||
                    p?.inspector?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p?.status?.toLowerCase().includes(searchKey?.toLowerCase())
            );
            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            refSearchText.current = e.target.value;
            filteredDataRef.current = filteredData;
            setIsPagination(false);
        } else {
            filteredDataRef.current = [];
            setResultData(process(schedulerAssignments, dataState));
            setGridData(process(schedulerAssignments, dataState));
            setIsPagination(true)
            filteredDataRef.current = schedulerAssignments;
            setCancelFilter(false)
        }
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                {/* <GridColumnMenuSort {...props} /> */}
                <GridColumnMenuFilter {...props} expanded={true} />
            </div>
        );
    };

    const columnProps = (field: string) => {
        return {
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: isColumnActive(field, dataState) ? "active" : "",
        };
    };
    const isColumnActive = (field: string, dataState: State) => {
        return (

            GridColumnMenuFilter.active(field, dataState.filter) ||
            GridColumnMenuSort.active(field, dataState.sort)
        );
    };


    const onSearchButtonClick = () => {
        setIsPagination(true);

        let startDate = fromDate;
        const endDate = toDate;


        if (startDate > endDate) {
            startDate = DEFAULT_DATES.start
            setFromDate(DEFAULT_DATES.start);
            setValid(true);
        }

        setStartAndEndDates({
            startDate: convertToTimezone(startDate).startOf("day").toISOString(),
            endDate: convertToTimezone(endDate).endOf("day").toISOString(),
        })

    }


    const handleDataStateChange = (e: GridPageChangeEvent) => {
        const newDataState = { skip: e.page.skip, take: e.page.take };
        setDataState(newDataState);
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = process(schedulerAssignments, dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
    };

    const toggleDialog = () => {
        setShowRequestAvailabilityStatus(false);
        setValid(false);
    };

    const aginColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    <td style={{ textAlign: 'center' }}>
                        {props.dataItem?.aging_Colour?.toLowerCase() === 'yellow' && (
                            <div style={{ width: "15px", height: "15px" }} className="scheduler-aging-yellow">
                            </div>)
                        }
                        {props.dataItem?.aging_Colour?.toLowerCase() === "red" &&
                            (<div style={{ width: "15px", height: "15px" }} className="scheduler-aging-red">
                            </div>)
                        }
                        {props.dataItem?.aging_Colour?.toLowerCase() === "green" &&
                            (<div style={{ width: "15px", height: "15px" }} className="scheduler-aging-green">
                            </div>)
                        }
                    </td>
                </div>
            </td>
        );
    };
    const RequestColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    {
                        (props.dataItem?.statusCode?.toLowerCase() === CLAIM_STATUS_CODE.CANCELED?.toLowerCase() || props?.dataItem?.statusCode?.toLowerCase() === CLAIM_STATUS_CODE.SCHEDULED?.toLowerCase()) ? <span>{props.dataItem.homeowneravailability}</span> : <button onClick={() => {
                            chkRef.current = props.dataItem;
                            setShowHomeownerScheduler(true)
                        }} className='btn btn-outline-primary ms-auto btn-sm text-capitalize'>Request</button>
                    }
                </div>
            </td>
        );
    };

    const CheckAvailabilityColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    {props.dataItem?.statusCode?.toLowerCase() === CLAIM_STATUS_CODE.CANCELED?.toLowerCase() || props?.dataItem?.statusCode?.toLowerCase() === CLAIM_STATUS_CODE?.SCHEDULED.toLowerCase() ? <span>{props.dataItem.inspector}</span> : <button onClick={() => {
                        setChkAvailability(true);
                        chkRef.current = props.dataItem;
                    }} className='btn btn-outline-primary ms-auto btn-sm text-capitalize'>Check Availability</button>
                    }
                </div>
            </td>
        );
    };

    const scheduledOnColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >

                <div>
                    {
                        formatDate(!isEmpty(props?.dataItem?.scheduleOn) && new Date(props?.dataItem?.scheduleOn), "MM/dd/yyyy")
                    }
                </div>

            </td>
        );
    };
    const createdOnColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    {
                        formatDate(!isEmpty(props?.dataItem?.createdOn) && new Date(props?.dataItem?.createdOn), "MM/dd/yyyy")
                    }
                </div>

            </td>
        );
    };
    const homwOwnerPhoneColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div className={props.className}>{!!props.dataItem?.[props.field] && formatPhoneNumber(props.dataItem[props.field])}</div>
            </td >
        );
    };
    const claimColumnCell = (props: any) => {
        return (
            <td
                {...props.tdProps}
                colSpan={1}
                style={{
                    color: "black",
                }}
            >
                <div>
                    {
                        <a className='text-decoration-underline' style={{ cursor: "pointer" }} onClick={(e) => {
                            nav("/profile", { state: { ...props.dataItem } })
                        }}>{props.dataItem.claimNo}</a>
                    }
                </div>
            </td >
        );
    };


    return (<>
        <div className='m-lg-5 mt-lg-3 m-4'>
            <div className="d-flex justify-content-between pt-3 pb-3">
                <div>
                    <input
                        ref={inputSearchRef}
                        type="text"
                        className="form-control ms-auto search-right-align searchBg"
                        onChange={onSearch}
                    />
                </div>
                <div className='d-flex' >
                    <div className='me-2'>
                        <DatePicker
                            selectsMultiple
                            openToDate={fromDate}
                            maxDate={toDate}
                            className="form-control ms-auto search-right-align calendarBg"
                            value={formatDate(fromDate, "MM/dd/yyyy")} placeholderText='To' dateFormat={"MM/dd/yyyy"} onChange={([date]) => {
                                //fromDateRef.current = date;
                                setFromDate(date);
                            }} />  </div>
                    <div className='me-2'>
                        <DatePicker
                            selectsMultiple
                            openToDate={toDate}
                            minDate={DEFAULT_DATES.start}
                            maxDate={maxDate}
                            className="form-control ms-auto search-right-align calendarBg"
                            value={formatDate(toDate, "MM/dd/yyyy")} placeholderText='To' dateFormat={"MM/dd/yyyy"} onChange={([date]) => {
                                setToDate(date);
                            }} />  </div>
                    <div>
                        <button className="btn-primary btn search-btn" onClick={() => {
                            // console.log(fromDateRef.current)
                            // console.log(toDateRef.current)
                            onSearchButtonClick();
                        }} type="submit" >
                            <svg width="17" height="17" viewBox="0 0 17.5 17.5">
                                <path id="Path_magnify" data-name="Path / magnify" d="M9.5,3a6.5,6.5,0,0,1,4.94,10.73l.27.27h.79l5,5L19,20.5l-5-5v-.79l-.27-.27A6.5,6.5,0,1,1,9.5,3m0,2A4.5,4.5,0,1,0,14,9.5,4.481,4.481,0,0,0,9.5,5Z" transform="translate(-3 -3)" fill="#fff" />
                            </svg>

                        </button>
                    </div>
                </div>
            </div>
            <DashboardCount inactiveClass={isInactiveFilter} onCancelFilterToggle={onStatusFilterToggle} onCancelDateToggle={onDateFilterToggle} sDate={startAndEndDates.startDate} fDate={startAndEndDates.endDate} />
            {availability ? <CheckAvailability onClose={() => { setChkAvailability(false); }} claimInfo={chkRef.current} show={availability} onAddOrDeleteSchedule={reloadSchedulerAssignments} />
                :
                null}
            {showRequestAvailabilityStatus &&
                <Dialog width="430px" title={" "} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">

                    <div className='messageGroup'>
                        <div >
                            <img src={mobileImage} />
                        </div>
                        <div >
                            <h6 style={{ textAlign: "center" }} >Availability request has been sent to the Insured</h6>
                        </div>
                    </div>

                </Dialog>
            }
            {isValid &&
                <Dialog title={"Validation"} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">
                    <div>
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                <p style={{ fontSize: "16px", fontWeight: "bold" }}>From Date should be less than To Date</p>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            <div className='card p-4 border-0 sw-shadow radius-15 border-0 '>
                {isErrorSchedulerAssignments && (<div>Error</div>)}
                {isLoadingSchedulerAssignments && (<LoadingIndicator isLoading />)}
                {isLoadingRequest && (<LoadingIndicator isLoading />)}
                {!isLoadingSchedulerAssignments && (
                    <Grid
                        // data={orderBy(gridData.data, sort)}
                        data={newData}
                        pageable={isPagination ? { pageSizes: [50, 100, 200, 500] } : null}
                        // pageable={{ pageSizes: [50, 100, 200, 500] }}
                        skip={dataState.skip}
                        take={dataState.take}
                        total={gridData.total}
                        groupable={true}
                        onExpandChange={expandChange}
                        onDataStateChange={dataStateChange}
                        resizable={true}
                        sortable={true}
                        {...dataState}
                        onPageChange={isPagination ? (e) => handleDataStateChange(e) : null}
                        // onPageChange={(e) => handleDataStateChange(e)}
                        columnVirtualization={false}
                    >
                        <GridColumn width={"65px"} field="aging_Colour" title="AGING"
                            cells={{ data: aginColumnCell }}
                        />
                        <GridColumn
                            width={"200px"}
                            field="claimNo"
                            {...columnProps("claimNo")}
                            columnMenu={ColumnMenu}
                            title="CLAIM#"
                            cells={{ data: claimColumnCell }}
                        />
                        <GridColumn width={"140px"} {...columnProps("policyNo")} title="POLICY#" field="policyNo" columnMenu={ColumnMenu} />
                        <GridColumn width={"120px"}  {...columnProps("keyStoneNo")} field="keyStoneNo" title="KEYSTONE#" columnMenu={ColumnMenu} />
                        <GridColumn width={"200px"}  {...columnProps("homeOwner")} field="homeOwner" title="INSURED" columnMenu={ColumnMenu} />
                        <GridColumn  {...columnProps("homeOwner_Phone")} field="homeOwner_Phone" columnMenu={ColumnMenu} width={"180px"} title="INSURED PHONE"
                            cells={{ data: homwOwnerPhoneColumnCell }}
                        />
                        <GridColumn {...columnProps("address")} columnMenu={ColumnMenu} width={"200px"} className='text-nowrap' field="address" title="ADDRESS" />
                        <GridColumn {...columnProps("state")} columnMenu={ColumnMenu} width={"120px"} className='text-nowrap' field="state" title="STATE" />
                        <GridColumn {...columnProps("scheduleOn")} field='scheduleOn' filter={'date'} className='text-nowrap' width={"140px"} columnMenu={ColumnMenu} title="SCHEDULED ON"
                            cells={{ data: scheduledOnColumnCell }}
                        />
                        <GridColumn width={"200px"}
                            columnMenu={ColumnMenu}
                            {...columnProps("inspector")}
                            field="inspector"
                            title="EXPERT"
                            cells={{ data: CheckAvailabilityColumnCell }}
                        />
                        <GridColumn width={"200px"}
                            columnMenu={ColumnMenu}
                            field="homeowneravailability"
                            title="INSURED AVAILABILITY"
                            {...columnProps("homeowneravailability")}
                            cells={{ data: RequestColumnCell }}
                        />
                        <GridColumn    {...columnProps("status")} columnMenu={ColumnMenu} width={"200px"} field="status" title="STATUS" />
                        <GridColumn    {...columnProps("createdOn")} field='createdOn' className='text-nowrap' width={"180px"} columnMenu={ColumnMenu} title="CREATED ON" format={"{0:MM/dd/yyyy}"} filter={'date'}
                            cells={{ data: createdOnColumnCell }}

                        />
                        <GridNoRecords>
                            <div style={{ width: "100%", height: "500px" }}>
                                <center>No data found</center>
                            </div>
                        </GridNoRecords>
                    </Grid>)}
                <div className='d-flex p-2 m-1'>
                    <div style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <div style={{ width: "15px", height: "15px" }} className="mt-1 scheduler-aging-green">
                        </div>
                        <div className="ms-2">On Time</div>
                    </div>
                    <div className="ms-2" style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
                        <div style={{ width: "15px", height: "15px" }} className="mt-1 scheduler-aging-yellow">
                        </div>
                        <div className="ms-1" style={{ height: "15px" }}>Risk</div>
                    </div>
                    <div className="ms-2" style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
                        <div style={{ width: "15px", height: "15px" }} className="mt-1 scheduler-aging-red">
                        </div>
                        <div className="ms-1" style={{ width: "100px", height: "15px" }}>Breach</div>
                    </div>
                </div>
            </div>

        </div >
        {showHomeownerScheduler && <ScheduleHomeowner
            onAddOrDeleteSchedule={reloadSchedulerAssignments}
            show={showHomeownerScheduler}
            claim={chkRef.current}
            onClose={() => setShowHomeownerScheduler(false)}
            setShowRequestAvailabilityStatus={setShowRequestAvailabilityStatus} />
        }
    </>);




};

export default SchedulerDashboard;

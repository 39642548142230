import React, { useMemo, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader, useLoadScript } from '@react-google-maps/api';

import { IClaimProfile } from '../../../models/scheduler/claim-profile';
import { isEmpty } from 'lodash';
import { GoogleMapValue } from '../../../models/scheduler/map-value';
import ZoomIcon from '../../../images/zoom';

const containerStyle = {
    width: '100%',
    height: '300px'
};
interface IProfileMap {
    claimDetails: IClaimProfile,
}

const ProfileMap: React.FunctionComponent<IProfileMap> = (props) => {
    const { claimDetails } = props;

    const [address, setAddress] = useState<string>("");
    let defaultMapValue = new GoogleMapValue();
    defaultMapValue.lat = 38.7946;
    defaultMapValue.lng = 106.5348;
    const [center, setCenter] = useState<GoogleMapValue>(defaultMapValue);
    const { isLoaded, } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg",
    });
    // const { isLoaded: isGoogleLoaded } = useLoadScript({
    //     googleMapsApiKey: "AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg",
    //     id: 'google-map-script'
    // });
    React.useEffect(() => {
        if (claimDetails) {
            if (!isEmpty(claimDetails?.lat) && !isEmpty(claimDetails?.long)) {
                const map = new GoogleMapValue();
                map.lat = parseFloat(claimDetails.lat.toString());
                map.lng = parseFloat(claimDetails.long.toString());
                setCenter(map);
                setAddress(claimDetails.homeOwner_Address);
            }
        }
    }, [claimDetails])



    return isLoaded ? (
        <div className='card border-0 sw-shadow radius-15 overflow-hidden'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                // options={{ clickableIcons: false, zoomControlOptions: { position: google.maps.ControlPosition.BOTTOM_RIGHT }, zoomControl: true, }}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
                id='map'
            >
                {/* Mark location */}
                <Marker position={center} />
            </GoogleMap>
            <div className='text-center p-2'>{address}</div>
        </div >
    ) : <></>
}
export default ProfileMap;
import type { AxiosError } from 'axios';
import axios from 'axios';
import { createQuery } from 'react-query-kit';
import { ScheduledSlot } from '../../types/ScheduledSlot';

type Params = {
    key: string,
}

type Response = {
    action: string;
    description: string;
    period: string;
    sCondition: string;
    sKey: string;
    sValue: string;
    userType: string;
};

export const useSLA = createQuery<Response, Params, AxiosError>({
    queryKey: ['sla'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetSLA', { params }).then(response => response.data?.result?.[0]),
    refetchOnWindowFocus: false
});

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useState } from 'react';
import { StorageKey } from '../../constants';
import { useStorage } from '../../hooks/use-storage';
import LogOut from '../../images/icon-logout.png';
import { Claim } from '../../models/claim/claim';
import { resetAxiosAuthTokens, } from '../../api-client.ts/axios';

export const LogoutComponent = () => {
    const [currentAuthState, setAuthState] = useStorage(StorageKey.AuthState, null);
    const [currentClaim, setCurrentClaim] = useStorage(StorageKey.CurrentUser, new Claim());
    const [isLogout, setIsLogout] = useState(false);

    const onLogOutClick = () => {
        setIsLogout(true);
    };

    const onCancelDialog = () => {
        setIsLogout(false);
    };

    const onLogout = () => {
        window.location.href = window.location.origin;
        setCurrentClaim(null);
        setAuthState(null);
        resetAxiosAuthTokens()
        setIsLogout(false);
    };

    return (
        <>
            <img src={LogOut} onClick={onLogOutClick} className="logout" />
            {isLogout && (
                <Dialog title="Log Out" onClose={onCancelDialog}>
                    <div><h5>Are you sure you want to logout?</h5></div>
                    <DialogActionsBar>
                        <div className="d-flex margin-fix mt-3 p-3">
                            <button className="btn btn-secondary btn-sm" onClick={onCancelDialog}>
                                Cancel
                            </button>
                            <button className="btn btn-primary ms-auto btn-sm" onClick={onLogout}>
                                Logout
                            </button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
        </>
    );
};

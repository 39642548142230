import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';
import { SendOtpResponse } from './use-send-mobile-otp';

type SendOtpPayload = { mobileNo?: string; countryCode?: string; userType: string, email?: string; preferedContactMethod: string };


export const useSendEmailMobileOtp = createMutation<SendOtpResponse, SendOtpPayload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/MobileAndEmailVerificationAndSendOTP',
            method: 'POST',
            data: payload
        }).then(response => response.data),
});
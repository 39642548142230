import dayjs from 'dayjs';
import * as React from 'react';
import SingleDropdown from '../../../components/single-select-dropdown';
import TimeSlot, { ISlot } from './timeslot-component';
import ManualTimeSelect from './manual-time-select.component';
import { IDateSlots, ISaveTimeSlot } from '../../../models/scheduler/save-time-slot';
import { usePostData } from '../../../hooks/use-post-data.hook';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { parseAsDateFromString } from '../../../utils';
import { CLAIM_STATUS_CODE } from '../utils';
import { useLoadData } from '../../../hooks/use-scheduler-load-data';
import { isEmpty } from 'lodash';
import ClockedIcon from '../../../images/clocked';
import ClockedIconRight from '../../../images/clock-right';
import { useSLAParametersTime } from '../../../api/dashboard/useSLAParameters';

export interface IAddAvailabilityProps {
    data: ISaveTimeSlot;
    onSuccess?: () => void;
    unAvailableDaySlots?: ISlot[];
    unAvailableTimeSlots?: ISlot[];
    showCancelButton?: boolean;
    onCancel?: () => void;
    claimId?: number
    timeInterval?: number;
    timeIntervalEndTime?: string
}



export function ManageAvailability(props: IAddAvailabilityProps) {

    const {
        onCancel,
        data,
        onSuccess = () => { },
        unAvailableDaySlots = [],
        unAvailableTimeSlots = [],
        claimId
    } = props;



    const [err, seterr] = React.useState<string>('');
    const [timeValidation, setTimeValidation] = React.useState<string>('');

    const [isAvailable, setIsAvailable] = React.useState<number>(1);
    const [isManualTimeSlot, setIsManualTimeSlot] = React.useState<boolean>(false);
    const { response, submitData } = usePostData('SchedulerAssignments/SaveTimeSlotMultiple');
    const { isSubmitting, error, result } = response;
    const [dayFrom, setDayFrom] = React.useState<number>(0);
    const [dayTo, setDayTo] = React.useState<number>(6);
    const dayIntervelRef = React.useRef<number>(0);
    const selectedSlots = React.useRef<ISlot[]>([]);
    const selectedTimeSlots = React.useRef<ISlot[]>([]);
    React.useEffect(() => {
        data.timeSlot = '';
        selectedTimeSlots.current = [];
        console.log(props?.timeInterval)
    }, [isManualTimeSlot]);

    const ontoggle = (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setIsManualTimeSlot(!isManualTimeSlot);
        setTimeValidation("");
    };

    const onNextClick = (e: any) => {
        dayIntervelRef.current = dayTo;
        let dayStart = dayTo;
        setDayFrom(dayStart);
        let dayTolocal = dayIntervelRef.current + 6;
        setDayTo(dayTolocal);

    };

    const onBeforeClick = (e: any) => {
        if (dayFrom > 0) {
            dayIntervelRef.current = dayTo - 1;
            let dayStart = dayFrom - 7;
            setDayFrom(dayStart);
            let dayTolocal = dayStart + 6;
            setDayTo(dayTolocal);

        }
    };

    const getUnAvailableDaySlots = (days: string[]) => {
        let slots: ISlot[] = [];
        let sData = days?.map((e) => formatDate(parseDate(e), 'EE,MM/dd/yyyy'));
        sData.forEach((e) => slots.push({ label: e, value: e }));
    };

    const getUnAvailableTimeSlots = (time: string[]) => {
        let slots: ISlot[] = [];

        time?.forEach((e) => slots.push({ label: e, value: e }));
    };
    React.useMemo(() => {
        if (result) {
            onCancel?.();
        }
    }, [result]);


    return <> <div className='instpecror-avail-section'>

        <div className="gredientBg90 outerPadding-fix">
            <center>
                <div style={{ color: 'red' }}>{err}</div>
            </center>
            <div
                style={{ position: 'relative', top: '-15px' }}
                className="d-flex justify-content-center"
            >
                <div className="text-dark" style={{ fontWeight: 'bold' }}>
                    Select Days
                </div>
                <div className="fixedButton">
                    <div
                        className="arrow-back"
                        onClick={onBeforeClick}
                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    ></div>
                    <div
                        className="arrow-next"
                        onClick={onNextClick}
                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    ></div>
                </div>
            </div>

            <TimeSlot
                unavailableSlots={unAvailableDaySlots}
                previousSelectedSlots={selectedSlots.current}
                formatType="days"
                diffIn="day"
                factor={1}
                from={dayjs().add(dayFrom, 'day')}
                manipulateType="day"
                to={dayjs().add(dayTo, 'day')}
                timeFormat="EE MM/dd/yyyy"
                onTimeSelected={(slots) => {
                    let slotvalues = slots.map((e) => e.value);
                    data.sDate = slotvalues.join(',');
                    console.log(data.sDate);
                    selectedSlots.current = slots;
                    err !== '' ? seterr('') : null;
                }}
            ></TimeSlot>
        </div>

        <div className="d-flex justify-content-center curvedBg web-none">
            <div className="text-dark" style={{ fontWeight: 'bold' }}>
                Select Time Slots
            </div>
            <div className="fixedButton">
                {isManualTimeSlot && (
                    <button
                        type="submit"
                        className="border-0 toggleBtn-style"
                        onClick={ontoggle}
                    >
                        <ClockedIcon></ClockedIcon>
                    </button>
                )}
                {!isManualTimeSlot && (
                    <button
                        type="submit"
                        className="border-0 toggleBtn-style"
                        onClick={ontoggle}
                    >
                        <ClockedIconRight></ClockedIconRight>
                    </button>
                )}
            </div>
        </div>
        <div className="outerPadding-time">
            <div className="curvedBg justifyalignFix ">
                <div className="text-dark" style={{ fontWeight: 'bold' }}>
                    Select Time Slots
                </div>
                <div className="fixedButton">
                    {isManualTimeSlot && (
                        <button
                            type="submit"
                            className="border-0 toggleBtn-style"
                            onClick={ontoggle}
                        >
                            <ClockedIcon></ClockedIcon>
                        </button>
                    )}
                    {!isManualTimeSlot && (
                        <button
                            type="submit"
                            className="border-0 toggleBtn-style"
                            onClick={ontoggle}
                        >
                            <ClockedIconRight></ClockedIconRight>
                        </button>
                    )}
                </div>
            </div>
            {!isManualTimeSlot ? (
                <TimeSlot
                    unavailableSlots={unAvailableTimeSlots}
                    previousSelectedSlots={selectedTimeSlots.current}
                    formatType="time"
                    diffIn="hours"
                    factor={props?.timeInterval}
                    from={dayjs().utc().startOf('date').add(10, 'hours')}
                    manipulateType="hours"
                    to={dayjs().utc().startOf('date').add(19, 'hours')}
                    timeFormat="h a"
                    onTimeSelected={(slots) => {
                        let slotsvalues = slots.map((e) => e.value);
                        selectedTimeSlots.current = slots;
                        data.timeSlot = slotsvalues.join(',');

                        // Update the data.sDate
                        //let slotValues = slots.map(e => e.value);
                        //data.sDate = slotValues.join(',');
                        //console.log(data.sDate);
                    }}
                ></TimeSlot>
            ) : (
                <>
                    {!isEmpty(timeValidation) ?
                        (<div><center>
                            <div style={{ color: 'red', fontSize: "15px" }}>{timeValidation}</div>
                        </center>
                            <br></br></div>) : (null)
                    }
                    <ManualTimeSelect
                        timeInterVal={props?.timeInterval}
                        timeWithInterval={props?.timeIntervalEndTime}
                        onchange={(timeRange) => {
                            data.timeSlot = `${timeRange.fromTime}-${timeRange.toTime}`;
                            //data.timeSlot = `${timeRange.fromTime}, ${timeRange.toTime}`;
                        }}
                    />
                </>
            )}
        </div>
    </div>
        <div className='footerstack'>
            <div style={{ padding: '10px 0px' }}>
                <SingleDropdown
                    dataitems={[
                        { text: 'Available', value: '1' },
                        { text: 'Unavailable', value: '0' },
                    ]}
                    value={isAvailable?.toString()}
                    onchange={(value: string) => {
                        data.isAvailable = value === '1';
                        data.slotStatus = parseInt(value) === 1 ? CLAIM_STATUS_CODE.AVAILABLE : CLAIM_STATUS_CODE.UNAVAILABLE;
                        setIsAvailable(parseInt(value));
                        console.log(data)
                    }}
                    placeholder='Select availability'
                    id={'isAvailable'}
                    showDefaultItem={false}
                />
            </div>
            <div className="d-flex justify-content-end" >
                {!!onCancel && <button
                    disabled={isSubmitting}
                    className="btn btn-secondary m-3"
                    onClick={onCancel}
                >
                    Cancel
                </button>}
                <button
                    disabled={isSubmitting}
                    className="btn btn-primary m-3"
                    onClick={async () => {
                        if (isManualTimeSlot && data.timeSlot === '') {
                            const endTime = (8 + props?.timeInterval);
                            let time = endTime > 9 ? endTime : "0" + endTime.toString();
                            data.timeSlot = `T08:00:00.000Z-T${time}:00:00.000Z`;
                        }
                        if (data.timeSlot === '' || data.sDate === '') {
                            seterr('Please select a date to continue');

                        } else {
                            seterr('');
                            setTimeValidation("");
                            let TimeSlots: IDateSlots[] = [];
                            data.sDate.split(',').forEach((e) => {
                                data.timeSlot.split(',').forEach((f) => {
                                    TimeSlots.push({
                                        FromDateTime: parseAsDateFromString(e, f.split('-')[0]),
                                        ToDateTime: parseAsDateFromString(e, f.split('-')[1]),
                                    });

                                });
                            });
                            if (isManualTimeSlot) {
                                if (data.timeSlot === '' || data.sDate === '') {
                                    seterr('Please select a date to continue');
                                    setTimeValidation("");
                                } else {
                                    const FromDt = dayjs(TimeSlots[0].FromDateTime).format('YYYY-MM-DDTHH:mm');
                                    const ToDt = dayjs(TimeSlots[0].ToDateTime).format('YYYY-MM-DDTHH:mm');
                                    const difHr = dayjs(ToDt).diff(FromDt, "hours");
                                    if (difHr < 0) {
                                        setTimeValidation("End time should be greater than start time")
                                    } else {
                                        if (difHr !== props?.timeInterval) {
                                            setTimeValidation(`Time interval between two times should be ${props?.timeInterval} hour`)
                                        } else {
                                            data.slotStatus = data.isAvailable ? CLAIM_STATUS_CODE.AVAILABLE : CLAIM_STATUS_CODE.UNAVAILABLE;
                                            data.dateSlots = TimeSlots;
                                            data.claimId = claimId
                                            await submitData({ data: data });
                                            if (response) {
                                                onCancel?.();
                                                onSuccess();
                                            }
                                        }
                                    }
                                }
                            } else {
                                data.slotStatus = data.isAvailable ? CLAIM_STATUS_CODE.AVAILABLE : CLAIM_STATUS_CODE.UNAVAILABLE;
                                data.dateSlots = TimeSlots;
                                data.claimId = claimId
                                await submitData({ data: data });
                                if (response) {
                                    onCancel?.();
                                    onSuccess();
                                }
                            }
                        }
                    }}
                >
                    Update
                </button>
            </div>
        </div>

    </>

}